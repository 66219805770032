<template>
  <span v-if="icon" :class="computedClasses" :style="{ width: `${size}px`, height: `${size}px` }" v-html="icon" />
</template>

<script setup lang="ts">
const props = defineProps<{
  name?: string;
  color?: string;
  size?: string;
}>();

const computedClasses = computed(() => {
  const classes = {
    'ui-icon': true,
    [`ui-icon-${props.color}`]: props.color, // Создаем класс на основе props.color, если он задан
    [`ui-icon-${props.size}`]: props.size, // Создаем класс на основе props.size, если он задан
  };
  return classes;
});

// Auto-load icons
const icons = Object.fromEntries(
  Object.entries(import.meta.glob('~/assets/images/icons/*.svg', { as: 'raw' })).map(([key, value]) => {
    const filename = key.split('/').pop()!.split('.').shift();
    return [filename, value];
  }),
);

// Lazily load the icon
const icon = props.name && (await icons?.[props.name]?.());
</script>

<style lang="scss">
.ui-icon {
  width: 28px;
  height: 28px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $gray100;
    }
  }

  &-white svg path {
    fill: white;
  }
  &-gray40 svg path {
    fill: $gray40;
  }
  &-gray60 svg path {
    fill: $gray60;
  }
  &-gray80 svg path {
    fill: $gray80;
  }
  &-red svg path {
    fill: $red;
  }
  &-sber svg path {
    fill: $sber;
  }
  &-portal svg path {
    fill: revert-layer;
  }
}
</style>
